.loaderBackground {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0vh;
    height: 100vh;
    text-align: center;
    background-color:#FFFFFF;
    opacity: 0.3;
    padding-top: 40vh;
}

.loading-text{
    margin-top: 15px;
    font-size: 21px;
    color: #18A1E2;
}