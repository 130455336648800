.bodyContainer{
    float: right;
    transition: 0.35s;
    overflow-x: hidden;
}

.paddingContainer{
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 10px;
    padding-bottom: 10px;
}