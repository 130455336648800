.cardContainer {
    width: 460px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 13px;
    margin: auto;
    background-color: #FFFFFF;
    padding: 10px;
    border: 0.5px solid lightgray;
    margin-bottom: 15px;
}

.cardHeaderContainer {
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: center;
}

.cardHeaderTitle {
    font-size: 29px;
    color: #262626;
}

.cardBodyContainer {
    margin: auto;
    margin-top: 70px;
    width: 400px;
}

.inputGroup {
    margin-top: 30px;
    text-align: start;
}

.inputLabel {
    display: block;
    margin-bottom: 4px;
    margin-left: 3px;
    color: #626567;
    font-size: 15px;
}

.input {
    display: block;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(153, 153, 153);
    font-size: 16px;
    padding: 0px 16px;
    height: 42px;
    outline: none;
    background-color: rgb(255, 255, 255);
}

.button {
    margin-top: 15px;
    width: 100%;
    border: none;
    border-radius: 8px;
    height: 45px;
    font-size: 14px;
    font-weight: 800;
    
    cursor: pointer;
}

.link {
    display: block;
}

.inputErrorLabel {
    display: flex;
    justify-content: flex-start;
    text-align: center;
    margin-top: 3px;
    padding-left: 5px;
    color: #CB4335;
}


@media (max-width: 568px) {
    .cardContainer {
        width: 98%;
    }

    .cardBodyContainer {
        width: 90%;
    }
}