.createButton {
    background-color: #18A1E2;
    color: white;
}

.cardContainer {
    margin-top: 20px;
    border-radius: 19px;
}

.actionsContainer{
    margin-top: 10px;
    justify-content: flex-end;
    padding: 10px 2%;
}


.inputGroup {
    display: flex;
    justify-content: space-between;
    gap: 3%
}

.dateTimeInput{
    margin-top: 30px;
}

.dateTimeGroupLeft{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 2%;
}

.dateTimeGroupRight{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 2%;
}

.infoInput {
    margin-top: 30px;
    width: 100%;
}
@media(max-width:990px){
    .inputGroup{
        display: block;
        height: auto;
    }

    .dateTimeGroupLeft{
        display: block;
        gap: 0;
    }

    .dateTimeGroupRight{
        display: block;
        gap: 0;
    }
 
    .dateTimeInput{
        margin-top:30px;
    }
    
    .infoInput {
        width: 100%;
    }
}