.firstColContainer {
    display: block;
    align-content: center;
    text-align: center;
    margin: auto;
    min-width: 70%;
}

.eventInfoContainer {
    width: 100%;
    align-items: center;
    display: flex;
}

.eventInfoMainContainer {
    display: block;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.eventTextContainer {
    display: flex;
    justify-content: flex-start;
}

.eventTextLabel {
    padding-left: 1%;
    padding-right: 1%;
}

.titleLabel {
    color: #262626;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
}

.infoLabel {
    color: rgba(38, 38, 38, 0.65);
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
}

.placeText{
    margin-right: 5px;
    /* margin-left: 5px */
}

.imageContainer{
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: -125px;
}

.infoContainer{
    display: block;
    margin-top: 20px;
    text-align: center;
    word-wrap: break-word;
}

.eventName{
    font-size: 26px;
    color: #262626;
    font-weight: 600;
    line-height: 28px;
}

.separator{
    margin-right:15px;
    margin-left:15px;
    font-size: 30px;
}

.artistName{
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 15px;
}

.eventDescription{
    margin-top: 25px;
    font-size: 16px;
    padding-right: 3%;
    padding-left: 3%;    
    text-align: left;
}

.ticketsSoldOut{
    height: 43px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    font-weight: 600;
    font-size: 14.5px;
    background-color: red;
    height: 43px;
    color: white;
    word-break: break-word;
}

.pdfButton{
    height: 43px;
    width: 100%;
    background-color: #cd4335;
    color: #ffffff;
    word-break: break-word;
}

.pdfButton:hover {
    opacity: 0.8;
    background-color: #cd4335;
}


@media(max-width:990px){

    .infoContainer{
        display: block;
        text-align: center;
        padding: 0;
    }

    .eventDescription{
        padding-right:25px;
        padding-left:25px;
        text-align: left;
    }

    .eventName{
        display: block;
    }

    .artistName{
        display: block;
        margin-top: 20px;
    }

    .separator{
        display: none;
    }
}