.signinLink{
    padding-top: 5px !important;
}

.signinLinkBottom{
    padding-top: 45px !important;
}

.linkForgetContainer{
    text-align: start;
    padding-left: 5px;
    margin-top: 20px;
}

.signInContainer{
    margin-top: 10vh;
}


@media (max-height: 968px) {
    .signInContainer {
      margin-top: 15px;
    }
  }