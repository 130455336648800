@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Inter&display=swap');

.sidebarContainer {
    background-color: #343A40;
    color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    /* box-shadow: 0 3px 3px rgb(0 0 0 / 0.2); */
    z-index: 4;
    transition: 0.35s;
    overflow-y: auto;
}

.sidebarContainerIcons {
    width: 70px;
    transition: 0.35s;
}

.sidebarContainerIcons:hover {
    width: 220px;
    transition: 0.35s;
}

.sidebarContainerFullWidth {
    width: 220px;
    transition: 0.3;
}

.sidebarMobileHidden {
    left: -300px;
    transition: 0.35s;
}

.logoContainer {
    padding-left: 10px;
    padding-right: 10px;
    height: 52px;
    border-bottom: 1px solid #EAEAEA;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}


.logoImage {
    max-width: 26px;
    cursor: pointer;
}

.companyTitle {
    margin-right: 5px;
    font-size: 22.5px;
    font-weight: 900;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    text-transform: lowercase;
    /* font-family: 'Fira Sans', sans-serif; */
    font-family: 'Inter', sans-serif;
    color: #18A1E2;
}

.sidebarContainerIcons:hover .companyTitle {
    display: inline-block;
}

.textHidden {
    display: none;
}

.optionsContainer {
    margin: 5px;
}

.option {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    
}

.optionButtonHeader {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 8px 10px;
    border: none;
    background-color: transparent;
    transition: 0.1s;
    
}

.optionButtonHeader:not(.optionButtonHeaderSelected):hover  {
    background-color: #dbdbdb !important;
}

.optionButtonHeaderSelected:hover{
    opacity: 0.8;

}

.sidebarContainerIcons:hover .optionButtonHeader {
    text-align: start !important;
}

.labelOption {
    text-transform: capitalize;
    font-size: 16px;
    margin-left: 10px;
    width: 140px !important;
    overflow: hidden;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.sidebarContainerIcons:hover .labelOption {
    display: inline-block;
}

.arrowGroup {
    width: 20;
    text-align: end;
    vertical-align: bottom;
    align-content: flex-end;
    height: 100%;
    cursor: pointer;
}

.sidebarContainerIcons:hover .arrowGroup {
    display: inline-block;
}

.sidebarContainerIcons:hover .childOpened {
    display: block;
}

.childrenButtonHeader {
    margin: auto;
    width: 98%;
    margin-top: 1px;
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 6px 10px;
    border: none;
    background-color: transparent;
    transition: 0.1s;
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.childrenButtonHeader:hover {
    background-color: #dbdbdb !important;
}

.childrenLabel{
    margin-left: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
}