.headerInfoContainer {
    display: flex;
}

.headerTextContainer {
    padding: 15px 20px;
}

.headerImage {
    border-radius: 14px;
}

.eventInfoName {
    font-weight: 900;
    font-size: 20px;
}

.eventInfoSeparator {
    margin-left: 15px;
    margin-right: 15px;
}

.headerDatesContainer {
    display: flex;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 900;
}

.headerPlaceContainer {
    display: flex;
    font-size: 16px;
    margin-top: 10px;
}

.sessionsCardHeader{
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.ticketQuantity{
    color: #262626;
    padding: 0 15px;
    font-size: 20px;
    font-weight: 900;
    user-select: none;
}

.ticketName{
    font-size: 17.5px;
    color: #262626;
    font-weight: 900;
    user-select: none;
}

.totalContainer{
    font-size:19px; 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
}

.buttonBuyContainer{
    height: auto;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom: 0.5px solid lightgray;
    display: flex;
    justify-content: center;
}

.artistName{
    font-size: 16px;
    margin-top: 10px;
    font-weight: 900;
}

@media(max-width:1300px) {
    .headerInfoContainer {
        display: block;
        text-align: center;
        width: 100%;
    }

    .headerTextContainer {
        padding: 0;
    }

    .headerImage {
        display: none;
    }

    .eventInfoText {
        display: block;
    }

    .eventInfoSeparator {
        display: none;
    }

    .eventInfoText {
        display: block;
        margin-bottom: 30px;
    }

    .headerDatesContainer {
        display: block;
    }

    .headerPlaceContainer {
        display: block;
        margin-top: 25px;
        justify-content: center;
    }
}