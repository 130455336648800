.container{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;
}

.cardContainerTickets{
    width: 60%;
    padding: 0px 15px;
    margin: 0;
}

.cardContainerSession{
    width: 35%;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
}

.cardTimeContainer{
    /* display: flex;
    justify-content: flex-end; */
    font-size: 17px;
    padding: 10px 2%;
}

.timerContainer{
    display: flex;
    justify-content: flex-end;
    height: 60px;
}

.cardTitleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #252525;
    padding: 0px 2%;
    margin-bottom: 50px;
}

.totalTitle{
    font-size: 21px;
    font-weight: 600;
}

.totalQuantity{
    font-size: 21px;
    font-weight: 600;
}

.bodyCardContainer{
    margin:25px 0;
    padding: 0;
    padding-right: 2%;
    padding-left: 2%;
    font-size: 17px;
    width: 100%;
}

.subtitleCardBody{
    font-weight: 600;
    color: #252525;
    margin-bottom: 10px;
    padding: 0;
}

.ticketCardContainer{
    margin-bottom: 35px;
}

.ticketCardInfoContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 16px;
    padding: 0;
}

.buttonFinishContainer{
    height: auto;
    border: none;
    text-align: center;
}

.eventImage{
    width: 100%;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
}

.eventName{
    font-size: 16px;
    font-weight: 600;
    color: #252525;
    margin-top: 5px;
}

.eventImageContainer{
    margin: 0;
    padding: 0;
}

.eventInfoContainer{
    margin-top: 15px;
    font-size: 15px;
}

.eventInfo{
    /* line-height: 20px; */
    margin-bottom: 8px;
}

.eventInfoTickets{
    margin-top: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
}

.summaryContainer{
    padding:0 2%;
    margin: 0;
    margin-top: 0;
    width: 100%;
}

.termsContainer{
    border: none;
    margin: 0;
    padding: 0 5px;
    height: auto;
    display: flex;
    align-items: center;
}

.termsLink{
    font-size: 16px;
}

@media(max-width:1200px){

    .container{
        display: block;
    }

    .cardContainerTickets{
        width: 98%;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    .cardContainerSession{
        width: 98%;
        margin: 0 auto;
        margin-bottom: 30px;
    }
}