.container{
    padding: 10px 3%;
}

.headerTitle{
    font-size: 25px;
    font-weight: 900;
}

@media(max-width:990px){
    .container{
        padding: 5px 0;
    }
}