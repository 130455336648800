.signinLink{
    padding-top: 5px !important;
}

.signinLinkBottom{
    padding-top: 45px !important;
}

.linkForgetContainer{
    text-align: start;
    padding-left: 5px;
    margin-top: 20px;
}

.signInContainer{
    margin-top: 15vh;
}

.signinLinkBottom{
    cursor: pointer;
    color: #18A1E2;
    font-size: 15px;
}

.closeIconContainer{
    display: flex;
    justify-content: flex-end;
    padding: 3px 1%;
    cursor: pointer;
}


@media (max-height: 968px) {
    .signInContainer {
      margin-top: 15px;
    }
  }