.cardContainer {
    margin-top: 20px;
    border-radius: 19px;
    padding: 1%;
}

.inputGroup{
    display: flex;
    justify-content: center;
    align-items: flex-start;
}


.inputContainer{
    min-width: 50%;
    margin-top: 30px;
    padding: 0px 1%;
}

.inputContainerFull{
    min-width: 50%;
    max-width: 100%;
    margin-top: 25px;
    padding: 0px 1%;
}

.errorPoster{
    margin-top: 20px;
    color: #D42F35;
    margin: 0 1%;
}

@media(max-width:990px){
    .inputGroup{
        display: block;
        height: auto;
    }

    
}