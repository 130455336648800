.createButton {
    background-color: #18A1E2;
    color: white;
}

.cardContainer {
    margin-top: 20px;
    border-radius: 19px;
    padding: 10;
    padding: 7px 0px;
}

.actionsContainer{
    margin-top: 10px;
    justify-content: flex-end;
    padding: 10px 2%;
}

.inputsContainer{
    padding: 10px 0px;
}

.inputGroup {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.dateTimeInput{
    margin-top: 10px;
    margin-right: 3%;

}

.dateTimeGroup{
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.infoInput {
    width: 20.5%;
    margin-top: 20px;
    margin-right: 10%;
}

.sessionsContainer{
    display: flex;
    gap: 2%
}

@media(max-width:990px){
    .inputGroup{
        display: block;
        height: auto;
    }

    .dateTimeGroup{
        display: block;
    }
 
    .dateTimeInput{
        margin: 0;
        margin-top:30px;
    }
    
    .infoInput {
        width: 100%;
    }

    .sessionsContainer{
        display: block;
        gap: 0;
    }
}