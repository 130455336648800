.createButtonContainer {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px
}

.createButton {
    background-color: #18A1E2
}

.eventList {
    margin-top: 30px;
    margin-bottom: 30px;
}

.firstColContainer {
    display: block;
    align-content: center;
    text-align: center;
    margin: auto;
    min-width: 70%;
}

.eventInfoContainer {
    width: 100%;
    align-items: center;
    display: flex;
}

.eventInfoMainContainer {
    display: block;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.eventTextContainer {
    display: flex;
    justify-content: flex-start;
}

.eventTextLabel {
    padding-left: 1%;
    padding-right: 1%;
}

.titleLabel {
    color: #262626;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.infoLabel {
    color: rgba(38, 38, 38, 0.65);
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
}