.body{
    width: 100vw;
    height: 100vh;
    background-color: #015D9A;
}

.mainbox {
    background-color: #015D9A;
    margin: auto;
    height: 600px;
    width: 600px;
    position: relative;
}
.err {
    color: #ffffff;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position: absolute;
    left: 20%;
    top: 8%;
}
.icon {
    position: absolute;
    font-size: 10rem;
    left: 39%;
    top: 4vh;
    color: #ffffff;
}
.err2 {
    color: #ffffff;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position: absolute;
    left: 68%;
    top: 8%;
}
.msg {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    position: absolute;
    left: 16%;
    top: 45%;
    width: 75%;
}
.goToHome {
    text-decoration: none;
    color: white;
}
.goToHome:hover {
    text-decoration: underline;
    color: white;
}

.icon {
    animation: spin infinite 5s linear;

    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
  }

@keyframes icon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}