.containerMobile {
    display: block;
}

.containerPc {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.title{
    color: #262626;
    font-size: 1.8rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
}