@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Inter&display=swap');
.navbarContainer {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    position: fixed;
    transition: 0.35s;
    box-shadow: 0px 1px 2px #999;
    z-index: 3;

}

.navbarLeftContainer {
    width: 20%;
    float: left;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.navbarRightContainer {
    width: 80%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.buttonMenu {
    background-color: transparent;
    border: none;
}

.buttonIcon {
    border: none;
    background-color: transparent;
    /* width: 50px; */
    height: 100%;
    text-align: center;
    cursor: pointer;
    padding: 0 10px;
    margin-right: 1%;
    margin-left: 1%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.buttonIcon:hover {
    opacity: 0.8;
}

.profileImage {
    width: 28px;
    border-radius: 30px;
    cursor: pointer;
}

.linkContainer {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.linkLabel {
    cursor: pointer;
    margin-left: 10px;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.linkLabel:hover {
    text-decoration: underline;
}

.navbarTitle {
    font-weight: 900;
    font-size: 25px;
    margin-right: 10px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-transform: lowercase;
    /* font-family: 'Fira Sans', sans-serif; */
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarTitleContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 1%;
}

.clientTitle{
    margin-left: 10px;
    font-size: 17px;
    font-weight: 900;
}

@media(max-width:990px){
    .clientTitle{
        display: none;
    }
}