.container{
    background-color: white;
    display: flex;
    padding: 10px;
    height: 100px;
    width: 100%;
    border: 0.1px solid lightgray;
    border-bottom: none;
    padding-right: 2%;
    padding-left: 2%;
}

.firstRow{
    flex: 0 1 auto;
    max-width: 20%;
    min-width: 100px;
    display: flex;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    word-break: break-all;
}

.middleRow{
    /* max-width: 60%; */
    flex: 2 2 auto;
    display: flex;
    padding-right: 2%;
    padding-left: 2%;
    word-break: break-all;
}

.lastRow{
    /* background-color: blue; */
    flex: 0 1 auto;
    max-width: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-break: break-all;
}

.image{
    max-width:20%;
    max-height:78px;
}

@media(max-width:1200px){
    .image{
        max-width:30%;
    }
}

@media(max-width:990px){
    .container{
        display: block;
        height: auto;
    }

    .firstRow{
        width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
        padding-top: 10px;
    }

    .middleRow{
        width: 100%;
        max-width: 100%;
    }

    .lastRow{
        width: 100%;
        max-width: 100%;
        justify-content: flex-end;
        margin-top: 30px;
        padding-bottom: 10px;
    }

    .image{
        max-width:40%;
    }
}