@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');

.pageTitle{
    font-size: 48px;
    line-height: 44px;
    font-weight: 400;
    line-height: 50px;
    color: #252525;
}

.pageSubtitle{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 100;
    font-size: 25px;
    line-height: 33px;
    color: #252525;
    margin-top: 50px;
}

.buttonServices, .buttonContact{
    text-transform: capitalize;
    font-size: 18px;
    padding: 13px 25px !important;
    border: 1px sol transparent;
    border-radius: 4px !important;
    box-shadow: 0 4px 4px rgba(0,0,0,0.3);
    touch-action: manipulation;
    text-decoration: none !important;
}

.buttonServices:hover, .buttonContact:hover{
    text-decoration: underline;
    color: #FFFFFF;
    opacity: 0.7;
}

.buttonServices{
    border: none !important;
    outline: none !important;
    background-color: #18A1E2 !important;
    color: #FFFFFF;
    text-decoration: none;
}

.buttonContact{
    border: none !important;
    outline: none !important;
    background-color: #EB601A !important;
    color: #FFFFFF;
    margin-left: 30px;
}

.buttonsContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
}


.imageBanner{
    max-width: 100%;
    max-height: 550px;
}

.infoHeader{
    width: 50%;
}

.imageDiv{
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.banner{
    display: flex;
    padding: 30px 3%;
}


@media(max-width:1200px){
    .banner{
        padding: 30px 5px;
        display: block;
        text-align: center;
    }

    .infoHeader{
        width: 100%;
        text-align: center;
    }
    
    .imageDiv{
        width: 100%;
        margin-top: 30px;
        justify-content: center;
    }


    .buttonsContainer{
        display: block;
    }

    .buttonServices, .buttonContact{
        width: 98%;
        display: block;
        margin: auto;
        margin-top: 15px;
    }
}