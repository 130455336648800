.signupLink{
    padding-top: 50px !important;
}

.termsContainer{
    display: flex;
    margin-top: 20px;
    margin-bottom: 8px;
    text-align: center;
    justify-content: flex-start;
    padding-left: 5px;
}

.signUpContainer{
    margin-top: 4vh;
}

.termsLink{
    font-size: 14.5px;
    margin-left: 10px;
}

.termsLink:hover{
    text-decoration: underline;
}

.passwordRemember{
   margin-top: 20px;
   padding-bottom: 10px;
   padding-right: 5px;
   padding-left:5px;
   color: #3a3a3a;
}

.passwordRememberTitle{
    font-size: 16px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 13px;
}

.signinLinkBottom{
    cursor: pointer;
    color: #18A1E2;
    font-size: 15px;
    margin-top: 35px;
}

.closeIconContainer{
    display: flex;
    justify-content: flex-end;
    padding: 3px 1%;
    cursor: pointer;
}

@media (max-height: 700px) {
    .signUpContainer {
      margin-top: 5px;
    }
  }