@import url('https://fonts.googleapis.com/css2?family=Lato&family=Source+Sans+Pro&display=swap');

@font-face {
  font-family: 'MyWebFont';
  src: local('MyWebFont'), url(./muli.regular.ttf)  format('truetype'), /* Safari, Android, iOS */
}

body {
  margin: 0;
  background-color: white;
  color: #757575;

}

