@import url(https://fonts.googleapis.com/css2?family=Lato&family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans&family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans&family=Inter&display=swap);
@font-face {
  font-family: 'MyWebFont';
  src: local('MyWebFont'), url(/static/media/muli.regular.9249d093.ttf)  format('truetype'), /* Safari, Android, iOS */
}

body {
  margin: 0;
  background-color: white;
  color: #757575;

}


.LoaderContainer_loaderBackground__1irQ_ {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0vh;
    height: 100vh;
    text-align: center;
    background-color:#FFFFFF;
    opacity: 0.3;
    padding-top: 40vh;
}

.LoaderContainer_loading-text__1SobD{
    margin-top: 15px;
    font-size: 21px;
    color: #18A1E2;
}
.pageNotFound_body__27s9w{
    width: 100vw;
    height: 100vh;
    background-color: #015D9A;
}

.pageNotFound_mainbox__2Ketg {
    background-color: #015D9A;
    margin: auto;
    height: 600px;
    width: 600px;
    position: relative;
}
.pageNotFound_err__1Ugym {
    color: #ffffff;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position: absolute;
    left: 20%;
    top: 8%;
}
.pageNotFound_icon__11SNJ {
    position: absolute;
    font-size: 10rem;
    left: 39%;
    top: 4vh;
    color: #ffffff;
}
.pageNotFound_err2__3VZ0t {
    color: #ffffff;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position: absolute;
    left: 68%;
    top: 8%;
}
.pageNotFound_msg__3yjup {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    position: absolute;
    left: 16%;
    top: 45%;
    width: 75%;
}
.pageNotFound_goToHome__2P-Es {
    text-decoration: none;
    color: white;
}
.pageNotFound_goToHome__2P-Es:hover {
    text-decoration: underline;
    color: white;
}

.pageNotFound_icon__11SNJ {
    -webkit-animation: pageNotFound_spin__1Xp3K infinite 5s linear;
            animation: pageNotFound_spin__1Xp3K infinite 5s linear;

    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
  }

@-webkit-keyframes pageNotFound_icon__11SNJ {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes pageNotFound_icon__11SNJ {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.Home_pageTitle__3J_84{
    font-size: 48px;
    line-height: 44px;
    font-weight: 400;
    line-height: 50px;
    color: #252525;
}

.Home_pageSubtitle__3ejKv{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 100;
    font-size: 25px;
    line-height: 33px;
    color: #252525;
    margin-top: 50px;
}

.Home_buttonServices__SvgFj, .Home_buttonContact__2hoSv{
    text-transform: capitalize;
    font-size: 18px;
    padding: 13px 25px !important;
    border: 1px sol transparent;
    border-radius: 4px !important;
    box-shadow: 0 4px 4px rgba(0,0,0,0.3);
    touch-action: manipulation;
    text-decoration: none !important;
}

.Home_buttonServices__SvgFj:hover, .Home_buttonContact__2hoSv:hover{
    text-decoration: underline;
    color: #FFFFFF;
    opacity: 0.7;
}

.Home_buttonServices__SvgFj{
    border: none !important;
    outline: none !important;
    background-color: #18A1E2 !important;
    color: #FFFFFF;
    text-decoration: none;
}

.Home_buttonContact__2hoSv{
    border: none !important;
    outline: none !important;
    background-color: #EB601A !important;
    color: #FFFFFF;
    margin-left: 30px;
}

.Home_buttonsContainer__358sv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
}


.Home_imageBanner__3zeIg{
    max-width: 100%;
    max-height: 550px;
}

.Home_infoHeader__1EkNe{
    width: 50%;
}

.Home_imageDiv__RWYUq{
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Home_banner__12ncY{
    display: flex;
    padding: 30px 3%;
}


@media(max-width:1200px){
    .Home_banner__12ncY{
        padding: 30px 5px;
        display: block;
        text-align: center;
    }

    .Home_infoHeader__1EkNe{
        width: 100%;
        text-align: center;
    }
    
    .Home_imageDiv__RWYUq{
        width: 100%;
        margin-top: 30px;
        justify-content: center;
    }


    .Home_buttonsContainer__358sv{
        display: block;
    }

    .Home_buttonServices__SvgFj, .Home_buttonContact__2hoSv{
        width: 98%;
        display: block;
        margin: auto;
        margin-top: 15px;
    }
}
.DefaultBodyContainer_bodyContainer__2Tn-q{
    float: right;
    transition: 0.35s;
    overflow-x: hidden;
}

.DefaultBodyContainer_paddingContainer__3p6jW{
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 10px;
    padding-bottom: 10px;
}
.Navbar_navbarContainer__bBkJ8 {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    position: fixed;
    transition: 0.35s;
    box-shadow: 0px 1px 2px #999;
    z-index: 3;

}

.Navbar_navbarLeftContainer__n0VzG {
    width: 20%;
    float: left;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.Navbar_navbarRightContainer__2Ujlf {
    width: 80%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.Navbar_buttonMenu__2wfje {
    background-color: transparent;
    border: none;
}

.Navbar_buttonIcon__mYRuG {
    border: none;
    background-color: transparent;
    /* width: 50px; */
    height: 100%;
    text-align: center;
    cursor: pointer;
    padding: 0 10px;
    margin-right: 1%;
    margin-left: 1%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.Navbar_buttonIcon__mYRuG:hover {
    opacity: 0.8;
}

.Navbar_profileImage__3eHy9 {
    width: 28px;
    border-radius: 30px;
    cursor: pointer;
}

.Navbar_linkContainer__3eysf {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.Navbar_linkLabel__1ijk6 {
    cursor: pointer;
    margin-left: 10px;
    font-size: 15px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Navbar_linkLabel__1ijk6:hover {
    text-decoration: underline;
}

.Navbar_navbarTitle__jNQ64 {
    font-weight: 900;
    font-size: 25px;
    margin-right: 10px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-transform: lowercase;
    /* font-family: 'Fira Sans', sans-serif; */
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Navbar_navbarTitleContainer__3BCZz {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 1%;
}

.Navbar_clientTitle__3QL6-{
    margin-left: 10px;
    font-size: 17px;
    font-weight: 900;
}

@media(max-width:990px){
    .Navbar_clientTitle__3QL6-{
        display: none;
    }
}
.Sidebar_sidebarContainer___r0ga {
    background-color: #343A40;
    color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    /* box-shadow: 0 3px 3px rgb(0 0 0 / 0.2); */
    z-index: 4;
    transition: 0.35s;
    overflow-y: auto;
}

.Sidebar_sidebarContainerIcons__sbvhr {
    width: 70px;
    transition: 0.35s;
}

.Sidebar_sidebarContainerIcons__sbvhr:hover {
    width: 220px;
    transition: 0.35s;
}

.Sidebar_sidebarContainerFullWidth__1pCQM {
    width: 220px;
    transition: 0.3;
}

.Sidebar_sidebarMobileHidden__1QxBa {
    left: -300px;
    transition: 0.35s;
}

.Sidebar_logoContainer__13Dal {
    padding-left: 10px;
    padding-right: 10px;
    height: 52px;
    border-bottom: 1px solid #EAEAEA;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}


.Sidebar_logoImage__3qX99 {
    max-width: 26px;
    cursor: pointer;
}

.Sidebar_companyTitle__2CtD2 {
    margin-right: 5px;
    font-size: 22.5px;
    font-weight: 900;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    text-transform: lowercase;
    /* font-family: 'Fira Sans', sans-serif; */
    font-family: 'Inter', sans-serif;
    color: #18A1E2;
}

.Sidebar_sidebarContainerIcons__sbvhr:hover .Sidebar_companyTitle__2CtD2 {
    display: inline-block;
}

.Sidebar_textHidden__2ZEby {
    display: none;
}

.Sidebar_optionsContainer__1Vyrk {
    margin: 5px;
}

.Sidebar_option__E-aHu {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    
}

.Sidebar_optionButtonHeader__17QWv {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 8px 10px;
    border: none;
    background-color: transparent;
    transition: 0.1s;
    
}

.Sidebar_optionButtonHeader__17QWv:not(.Sidebar_optionButtonHeaderSelected__3b9ii):hover  {
    background-color: #dbdbdb !important;
}

.Sidebar_optionButtonHeaderSelected__3b9ii:hover{
    opacity: 0.8;

}

.Sidebar_sidebarContainerIcons__sbvhr:hover .Sidebar_optionButtonHeader__17QWv {
    text-align: start !important;
}

.Sidebar_labelOption__fFazS {
    text-transform: capitalize;
    font-size: 16px;
    margin-left: 10px;
    width: 140px !important;
    overflow: hidden;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Sidebar_sidebarContainerIcons__sbvhr:hover .Sidebar_labelOption__fFazS {
    display: inline-block;
}

.Sidebar_arrowGroup__1dpFj {
    width: 20;
    text-align: end;
    vertical-align: bottom;
    align-content: flex-end;
    height: 100%;
    cursor: pointer;
}

.Sidebar_sidebarContainerIcons__sbvhr:hover .Sidebar_arrowGroup__1dpFj {
    display: inline-block;
}

.Sidebar_sidebarContainerIcons__sbvhr:hover .Sidebar_childOpened__2DKvm {
    display: block;
}

.Sidebar_childrenButtonHeader__a7iED {
    margin: auto;
    width: 98%;
    margin-top: 1px;
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 6px 10px;
    border: none;
    background-color: transparent;
    transition: 0.1s;
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.Sidebar_childrenButtonHeader__a7iED:hover {
    background-color: #dbdbdb !important;
}

.Sidebar_childrenLabel__2NZEY{
    margin-left: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
}
.CardContainer_cardContainer__1_lWw {
    width: 460px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 13px;
    margin: auto;
    background-color: #FFFFFF;
    padding: 10px;
    border: 0.5px solid lightgray;
    margin-bottom: 15px;
}

.CardContainer_cardHeaderContainer__343GH {
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: center;
}

.CardContainer_cardHeaderTitle__1CnYY {
    font-size: 29px;
    color: #262626;
}

.CardContainer_cardBodyContainer__1eYAn {
    margin: auto;
    margin-top: 70px;
    width: 400px;
}

.CardContainer_inputGroup__3bQEh {
    margin-top: 30px;
    text-align: start;
}

.CardContainer_inputLabel__2p0Bx {
    display: block;
    margin-bottom: 4px;
    margin-left: 3px;
    color: #626567;
    font-size: 15px;
}

.CardContainer_input__3-54q {
    display: block;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(153, 153, 153);
    font-size: 16px;
    padding: 0px 16px;
    height: 42px;
    outline: none;
    background-color: rgb(255, 255, 255);
}

.CardContainer_button__3mz-Z {
    margin-top: 15px;
    width: 100%;
    border: none;
    border-radius: 8px;
    height: 45px;
    font-size: 14px;
    font-weight: 800;
    
    cursor: pointer;
}

.CardContainer_link__HHrAG {
    display: block;
}

.CardContainer_inputErrorLabel__15aRx {
    display: flex;
    justify-content: flex-start;
    text-align: center;
    margin-top: 3px;
    padding-left: 5px;
    color: #CB4335;
}


@media (max-width: 568px) {
    .CardContainer_cardContainer__1_lWw {
        width: 98%;
    }

    .CardContainer_cardBodyContainer__1eYAn {
        width: 90%;
    }
}
.SignIn_signinLink__14kzS{
    padding-top: 5px !important;
}

.SignIn_signinLinkBottom__3zBUM{
    padding-top: 45px !important;
}

.SignIn_linkForgetContainer__3C-f2{
    text-align: start;
    padding-left: 5px;
    margin-top: 20px;
}

.SignIn_signInContainer__1Wd3k{
    margin-top: 10vh;
}


@media (max-height: 968px) {
    .SignIn_signInContainer__1Wd3k {
      margin-top: 15px;
    }
  }
.Line_line__2DdEL{
    border-width: 1px 0px 0px;
    border-right-style: none;
    border-right-style: initial;
    border-bottom-style: none;
    border-bottom-style: initial;
    border-left-style: none;
    border-left-style: initial;
    border-right-color: currentColor;
    border-right-color: initial;
    border-bottom-color: currentColor;
    border-bottom-color: initial;
    border-left-color: currentColor;
    border-left-color: initial;
    border-image: none;
    border-image: initial;
    border-top-style: solid;
    width: 100%;
}
.SignUp_signupLink__24-_8{
    padding-top: 50px !important;
}

.SignUp_termsContainer__AGZHP{
    display: flex;
    margin-top: 20px;
    margin-bottom: 8px;
    text-align: center;
    justify-content: flex-start;
    padding-left: 5px;
}

.SignUp_signUpContainer__1_N5I{
    margin-top: 2vh;
}

.SignUp_termsLink__fvh15{
    font-size: 14.5px;
    margin-left: 10px;
}

.SignUp_termsLink__fvh15:hover{
    text-decoration: underline;
}

.SignUp_passwordRemember__2l5a8{
   margin-top: 20px;
   padding-bottom: 10px;
   padding-right: 5px;
   padding-left:5px;
   color: #3a3a3a;
}

.SignUp_passwordRememberTitle__aQn5t{
    font-size: 16px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 13px;
}

@media (max-height: 700px) {
    .SignUp_signUpContainer__1_N5I {
      margin-top: 5px;
    }
  }
.PageTitle_containerMobile__1w2oH {
    display: block;
}

.PageTitle_containerPc__36c7M {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.PageTitle_title__Zqh0g{
    color: #262626;
    font-size: 1.8rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
}
.CardItemContainer_container__SFcj1{
    background-color: white;
    display: flex;
    padding: 10px;
    height: 100px;
    width: 100%;
    border: 0.1px solid lightgray;
    border-bottom: none;
    padding-right: 2%;
    padding-left: 2%;
}

.CardItemContainer_firstRow__1CRwY{
    flex: 0 1 auto;
    max-width: 20%;
    min-width: 100px;
    display: flex;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    word-break: break-all;
}

.CardItemContainer_middleRow__3OEs-{
    /* max-width: 60%; */
    flex: 2 2 auto;
    display: flex;
    padding-right: 2%;
    padding-left: 2%;
    word-break: break-all;
}

.CardItemContainer_lastRow__1qknP{
    /* background-color: blue; */
    flex: 0 1 auto;
    max-width: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-break: break-all;
}

.CardItemContainer_image__2zg3K{
    max-width:20%;
    max-height:78px;
}

@media(max-width:1200px){
    .CardItemContainer_image__2zg3K{
        max-width:30%;
    }
}

@media(max-width:990px){
    .CardItemContainer_container__SFcj1{
        display: block;
        height: auto;
    }

    .CardItemContainer_firstRow__1CRwY{
        width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
        padding-top: 10px;
    }

    .CardItemContainer_middleRow__3OEs-{
        width: 100%;
        max-width: 100%;
    }

    .CardItemContainer_lastRow__1qknP{
        width: 100%;
        max-width: 100%;
        justify-content: flex-end;
        margin-top: 30px;
        padding-bottom: 10px;
    }

    .CardItemContainer_image__2zg3K{
        max-width:40%;
    }
}
.EventList_createButtonContainer__2BF79 {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px
}

.EventList_createButton__c4OZ0 {
    background-color: #18A1E2
}

.EventList_eventList__1N0rA {
    margin-top: 30px;
    margin-bottom: 30px;
}

.EventList_firstColContainer__2hChg {
    display: block;
    align-content: center;
    text-align: center;
    margin: auto;
    min-width: 70%;
}

.EventList_eventInfoContainer__1G7I8 {
    width: 100%;
    align-items: center;
    display: flex;
}

.EventList_eventInfoMainContainer__WVCP8 {
    display: block;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.EventList_eventTextContainer__1nZC4 {
    display: flex;
    justify-content: flex-start;
}

.EventList_eventTextLabel__1hk_a {
    padding-left: 1%;
    padding-right: 1%;
}

.EventList_titleLabel__2egHC {
    color: #262626;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.EventList_infoLabel__22UsB {
    color: rgba(38, 38, 38, 0.65);
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
}
.CreateEvent_saveButton__1wd9M{
    background-color: #90CB15;
    color: white;
}

.CreateEvent_saveButton__1wd9M:hover{
    background-color: #709E10;
}
.InfoCreateEventTab_cardContainer__3SThf {
    margin-top: 20px;
    border-radius: 19px;
    padding: 1%;
}

.InfoCreateEventTab_inputGroup__23m7D{
    display: flex;
    justify-content: center;
    align-items: flex-start;
}


.InfoCreateEventTab_inputContainer__1gzO_{
    min-width: 50%;
    margin-top: 30px;
    padding: 0px 1%;
}

.InfoCreateEventTab_inputContainerFull__yVKOF{
    min-width: 50%;
    max-width: 100%;
    margin-top: 25px;
    padding: 0px 1%;
}

.InfoCreateEventTab_errorPoster__1ygt5{
    margin-top: 20px;
    color: #D42F35;
    margin: 0 1%;
}

@media(max-width:990px){
    .InfoCreateEventTab_inputGroup__23m7D{
        display: block;
        height: auto;
    }

    
}
.SessionsCreateEventTab_createButton__2rxCz {
    background-color: #18A1E2;
    color: white;
}

.SessionsCreateEventTab_cardContainer__nQ8ZW {
    margin-top: 20px;
    border-radius: 19px;
}

.SessionsCreateEventTab_actionsContainer__2SS_d{
    margin-top: 10px;
    justify-content: flex-end;
    padding: 10px 2%;
}


.SessionsCreateEventTab_inputGroup__8DfkT {
    display: flex;
    justify-content: space-between;
    grid-gap: 3%;
    gap: 3%
}

.SessionsCreateEventTab_dateTimeInput__3foii{
    margin-top: 30px;
}

.SessionsCreateEventTab_dateTimeGroupLeft__2zoRq{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    grid-gap: 2%;
    gap: 2%;
}

.SessionsCreateEventTab_dateTimeGroupRight__G78ds{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-gap: 2%;
    gap: 2%;
}

.SessionsCreateEventTab_infoInput__2GBoi {
    margin-top: 30px;
    width: 100%;
}
@media(max-width:990px){
    .SessionsCreateEventTab_inputGroup__8DfkT{
        display: block;
        height: auto;
    }

    .SessionsCreateEventTab_dateTimeGroupLeft__2zoRq{
        display: block;
        grid-gap: 0;
        gap: 0;
    }

    .SessionsCreateEventTab_dateTimeGroupRight__G78ds{
        display: block;
        grid-gap: 0;
        gap: 0;
    }
 
    .SessionsCreateEventTab_dateTimeInput__3foii{
        margin-top:30px;
    }
    
    .SessionsCreateEventTab_infoInput__2GBoi {
        width: 100%;
    }
}
.TicketsCreateEventTab_createButton__37taF {
    background-color: #18A1E2;
    color: white;
}

.TicketsCreateEventTab_cardContainer__1AnQw {
    margin-top: 20px;
    border-radius: 19px;
    padding: 10;
    padding: 7px 0px;
}

.TicketsCreateEventTab_actionsContainer__2Uzxr{
    margin-top: 10px;
    justify-content: flex-end;
    padding: 10px 2%;
}

.TicketsCreateEventTab_inputsContainer__2LqUN{
    padding: 10px 0px;
}

.TicketsCreateEventTab_inputGroup__2BA3b {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.TicketsCreateEventTab_dateTimeInput__LPibe{
    margin-top: 10px;
    margin-right: 3%;

}

.TicketsCreateEventTab_dateTimeGroup__1tJgB{
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.TicketsCreateEventTab_infoInput__3oaZ4 {
    width: 20.5%;
    margin-top: 20px;
    margin-right: 10%;
}

.TicketsCreateEventTab_sessionsContainer__1H76g{
    display: flex;
    grid-gap: 2%;
    gap: 2%
}

@media(max-width:990px){
    .TicketsCreateEventTab_inputGroup__2BA3b{
        display: block;
        height: auto;
    }

    .TicketsCreateEventTab_dateTimeGroup__1tJgB{
        display: block;
    }
 
    .TicketsCreateEventTab_dateTimeInput__LPibe{
        margin: 0;
        margin-top:30px;
    }
    
    .TicketsCreateEventTab_infoInput__3oaZ4 {
        width: 100%;
    }

    .TicketsCreateEventTab_sessionsContainer__1H76g{
        display: block;
        grid-gap: 0;
        gap: 0;
    }
}
.ClientEvents_firstColContainer__2i2MR {
    display: block;
    align-content: center;
    text-align: center;
    margin: auto;
    min-width: 70%;
}

.ClientEvents_eventInfoContainer__39AXJ {
    width: 100%;
    align-items: center;
    display: flex;
}

.ClientEvents_eventInfoMainContainer__1dZKX {
    display: block;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.ClientEvents_eventTextContainer__33R5i {
    display: flex;
    justify-content: flex-start;
}

.ClientEvents_eventTextLabel__3RqTy {
    padding-left: 1%;
    padding-right: 1%;
}

.ClientEvents_titleLabel__SOSnh {
    color: #262626;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
}

.ClientEvents_infoLabel__2OY4h {
    color: rgba(38, 38, 38, 0.65);
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
}
.EventInfo_headerBox__2KZ-_{
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1%;
    padding-left: 1%;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 200px;
}

.EventInfo_sessionsCardHeader__21rpM{
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.EventInfo_titleContainer__2zU9L{
    display: flex;
    justify-content: center;
}

.EventInfo_imageContainer__2ErFk{
    display: flex;
    justify-content: flex-start;
    margin-left: 100px;
}

.EventInfo_titleLine__3T6G_{
    margin-right:10px;
    margin-left:10px;
}

.EventInfo_sessionsContainer__1IOsF{
    margin-top: 30px;
    padding-right: 20px;
    padding-left: 20px ;
}

.EventInfo_firstColContainer__3nAbQ {
    display: block;
    align-content: center;
    text-align: center;
    margin: auto;
    min-width: 70%;
}

.EventInfo_eventInfoContainer__1e_8d {
    width: 100%;
    align-items: center;
    display: flex;
}

.EventInfo_eventInfoMainContainer__FBoCZ {
    display: block;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.EventInfo_eventTextContainer__2aTNB {
    display: flex;
    justify-content: flex-start;
}

.EventInfo_eventTextLabel__3BZgN {
    padding-left: 1%;
    padding-right: 1%;
}

.EventInfo_titleLabel__1tZLc {
    color: #262626;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
}

.EventInfo_infoLabel__WEqbd {
    color: rgba(38, 38, 38, 0.65);
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
}

.EventInfo_placeText__gZkBZ{
    margin-right: 5px;
    /* margin-left: 5px */
}

.EventInfo_imageContainer__2ErFk{
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: -125px;
}

.EventInfo_infoContainer__2oQJr{
    display: block;
    margin-top: 20px;
    text-align: center;
    word-wrap: break-word;
}

.EventInfo_eventName__2WCRo{
    font-size: 26px;
    color: #262626;
    font-weight: 600;
    line-height: 28px;
}

.EventInfo_separator__3ZwC6{
    margin-right:15px;
    margin-left:15px;
    font-size: 30px;
}

.EventInfo_artistName__3z0EG{
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 15px;
}

.EventInfo_eventDescription__3JYw6{
    margin-top: 25px;
    font-size: 16px;
    padding-right: 3%;
    padding-left: 3%;    
    text-align: left;
}

.EventInfo_ticketsSoldOut__16xBx{
    height: 43px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    font-weight: 600;
    font-size: 14.5px;
    background-color: red;
    height: 43px;
    color: white;
    word-break: break-word;
}

@media(max-width:990px){
    .EventInfo_headerBox__2KZ-_{
        display: block;
    }
    
    .EventInfo_titleContainer__2zU9L{
        width: 100%;
    }

    .EventInfo_infoContainer__2oQJr{
        display: block;
        text-align: center;
        padding: 0;
    }

    .EventInfo_eventDescription__3JYw6{
        padding-right:25px;
        padding-left:25px;
        text-align: left;
    }

    .EventInfo_eventName__2WCRo{
        display: block;
    }

    .EventInfo_artistName__3z0EG{
        display: block;
        margin-top: 20px;
    }

    .EventInfo_separator__3ZwC6{
        display: none;
    }
}
.EventTickets_headerInfoContainer__1fkbZ {
    display: flex;
}

.EventTickets_headerTextContainer__3geoG {
    padding: 15px 20px;
}

.EventTickets_headerImage__2uRbp {
    border-radius: 14px;
}

.EventTickets_eventInfoName__OBxCu {
    font-weight: 900;
    font-size: 20px;
}

.EventTickets_eventInfoSeparator__2WXyy {
    margin-left: 15px;
    margin-right: 15px;
}

.EventTickets_headerDatesContainer__1_2Io {
    display: flex;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 900;
}

.EventTickets_headerPlaceContainer__2ALl8 {
    display: flex;
    font-size: 16px;
    margin-top: 10px;
}

.EventTickets_sessionsCardHeader__2H4wy{
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.EventTickets_ticketQuantity__3pygP{
    color: #262626;
    padding: 0 15px;
    font-size: 20px;
    font-weight: 900;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.EventTickets_ticketName__1lGAu{
    font-size: 17.5px;
    color: #262626;
    font-weight: 900;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.EventTickets_totalContainer__3d6Ie{
    font-size:19px; 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.EventTickets_buttonBuyContainer__1sdKF{
    height: auto;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom: 0.5px solid lightgray;
    display: flex;
    justify-content: center;
}

.EventTickets_artistName__3ZEwQ{
    font-size: 16px;
    margin-top: 10px;
    font-weight: 900;
}

@media(max-width:1300px) {
    .EventTickets_headerInfoContainer__1fkbZ {
        display: block;
        text-align: center;
        width: 100%;
    }

    .EventTickets_headerTextContainer__3geoG {
        padding: 0;
    }

    .EventTickets_headerImage__2uRbp {
        display: none;
    }

    .EventTickets_eventInfoText__8B6fz {
        display: block;
    }

    .EventTickets_eventInfoSeparator__2WXyy {
        display: none;
    }

    .EventTickets_eventInfoText__8B6fz {
        display: block;
        margin-bottom: 30px;
    }

    .EventTickets_headerDatesContainer__1_2Io {
        display: block;
    }

    .EventTickets_headerPlaceContainer__2ALl8 {
        display: block;
        margin-top: 25px;
        justify-content: center;
    }
}
.SignInModal_signinLink__v2vPp{
    padding-top: 5px !important;
}

.SignInModal_signinLinkBottom__3RRiI{
    padding-top: 45px !important;
}

.SignInModal_linkForgetContainer__1biLQ{
    text-align: start;
    padding-left: 5px;
    margin-top: 20px;
}

.SignInModal_signInContainer__F22fe{
    margin-top: 15vh;
}

.SignInModal_signinLinkBottom__3RRiI{
    cursor: pointer;
    color: #18A1E2;
    font-size: 15px;
}

.SignInModal_closeIconContainer__1UnoJ{
    display: flex;
    justify-content: flex-end;
    padding: 3px 1%;
    cursor: pointer;
}


@media (max-height: 968px) {
    .SignInModal_signInContainer__F22fe {
      margin-top: 15px;
    }
  }
.SignUpModal_signupLink__a0pGR{
    padding-top: 50px !important;
}

.SignUpModal_termsContainer__3T3T4{
    display: flex;
    margin-top: 20px;
    margin-bottom: 8px;
    text-align: center;
    justify-content: flex-start;
    padding-left: 5px;
}

.SignUpModal_signUpContainer__2PCRX{
    margin-top: 4vh;
}

.SignUpModal_termsLink__1-uuz{
    font-size: 14.5px;
    margin-left: 10px;
}

.SignUpModal_termsLink__1-uuz:hover{
    text-decoration: underline;
}

.SignUpModal_passwordRemember__2aMQT{
   margin-top: 20px;
   padding-bottom: 10px;
   padding-right: 5px;
   padding-left:5px;
   color: #3a3a3a;
}

.SignUpModal_passwordRememberTitle__M7bMP{
    font-size: 16px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 13px;
}

.SignUpModal_signinLinkBottom__ubkb5{
    cursor: pointer;
    color: #18A1E2;
    font-size: 15px;
    margin-top: 35px;
}

.SignUpModal_closeIconContainer__3zPsZ{
    display: flex;
    justify-content: flex-end;
    padding: 3px 1%;
    cursor: pointer;
}

@media (max-height: 700px) {
    .SignUpModal_signUpContainer__2PCRX {
      margin-top: 5px;
    }
  }
.InscriptionCheckout_container__3woIp{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;
}

.InscriptionCheckout_cardContainerTickets__2h32C{
    width: 60%;
    padding: 0px 15px;
    margin: 0;
}

.InscriptionCheckout_cardContainerSession__1lyEt{
    width: 35%;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
}

.InscriptionCheckout_cardTimeContainer__benDd{
    /* display: flex;
    justify-content: flex-end; */
    font-size: 17px;
    padding: 10px 2%;
}

.InscriptionCheckout_timerContainer__3gtmi{
    display: flex;
    justify-content: flex-end;
    height: 60px;
}

.InscriptionCheckout_cardTitleContainer__2tiqc{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #252525;
    padding: 0px 2%;
    margin-bottom: 50px;
}

.InscriptionCheckout_totalTitle__275LG{
    font-size: 21px;
    font-weight: 600;
}

.InscriptionCheckout_totalQuantity__1rC-C{
    font-size: 21px;
    font-weight: 600;
}

.InscriptionCheckout_bodyCardContainer__1ewRD{
    margin:25px 0;
    padding: 0;
    padding-right: 2%;
    padding-left: 2%;
    font-size: 17px;
    width: 100%;
}

.InscriptionCheckout_subtitleCardBody__3qMcq{
    font-weight: 600;
    color: #252525;
    margin-bottom: 10px;
    padding: 0;
}

.InscriptionCheckout_ticketCardContainer__1OsLF{
    margin-bottom: 35px;
}

.InscriptionCheckout_ticketCardInfoContainer__1RCO2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 16px;
    padding: 0;
}

.InscriptionCheckout_buttonFinishContainer__2PO13{
    height: auto;
    border: none;
    text-align: center;
}

.InscriptionCheckout_eventImage__3HoK8{
    width: 100%;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
}

.InscriptionCheckout_eventName__2-l_8{
    font-size: 16px;
    font-weight: 600;
    color: #252525;
    margin-top: 5px;
}

.InscriptionCheckout_eventImageContainer__2sxlY{
    margin: 0;
    padding: 0;
}

.InscriptionCheckout_eventInfoContainer__hBqin{
    margin-top: 15px;
    font-size: 15px;
}

.InscriptionCheckout_eventInfo__tprDM{
    /* line-height: 20px; */
    margin-bottom: 8px;
}

.InscriptionCheckout_eventInfoTickets__3QZkt{
    margin-top: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
}

.InscriptionCheckout_summaryContainer__GuBjO{
    padding:0 2%;
    margin: 0;
    margin-top: 0;
    width: 100%;
}

.InscriptionCheckout_termsContainer__1EEQG{
    border: none;
    margin: 0;
    padding: 0 5px;
    height: auto;
    display: flex;
    align-items: center;
}

.InscriptionCheckout_termsLink__1OI-_{
    font-size: 16px;
}

@media(max-width:1200px){

    .InscriptionCheckout_container__3woIp{
        display: block;
    }

    .InscriptionCheckout_cardContainerTickets__2h32C{
        width: 98%;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    .InscriptionCheckout_cardContainerSession__1lyEt{
        width: 98%;
        margin: 0 auto;
        margin-bottom: 30px;
    }
}
.MyTickets_container__2TRXX{
    padding: 10px 3%;
}

.MyTickets_headerTitle__2P58X{
    font-size: 25px;
    font-weight: 900;
}

@media(max-width:990px){
    .MyTickets_container__2TRXX{
        padding: 5px 0;
    }
}
.EventTicketCard_firstColContainer__228Bd {
    display: block;
    align-content: center;
    text-align: center;
    margin: auto;
    min-width: 70%;
}

.EventTicketCard_eventInfoContainer__1DIPK {
    width: 100%;
    align-items: center;
    display: flex;
}

.EventTicketCard_eventInfoMainContainer__12owC {
    display: block;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.EventTicketCard_eventTextContainer__3lf7d {
    display: flex;
    justify-content: flex-start;
}

.EventTicketCard_eventTextLabel__1PasJ {
    padding-left: 1%;
    padding-right: 1%;
}

.EventTicketCard_titleLabel__3dp1e {
    color: #262626;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
}

.EventTicketCard_infoLabel__10R4b {
    color: rgba(38, 38, 38, 0.65);
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
}

.EventTicketCard_placeText__3k3Tu{
    margin-right: 5px;
    /* margin-left: 5px */
}

.EventTicketCard_imageContainer__1f3jM{
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: -125px;
}

.EventTicketCard_infoContainer__tk0Uw{
    display: block;
    margin-top: 20px;
    text-align: center;
    word-wrap: break-word;
}

.EventTicketCard_eventName__1pDWW{
    font-size: 26px;
    color: #262626;
    font-weight: 600;
    line-height: 28px;
}

.EventTicketCard_separator__8c-jL{
    margin-right:15px;
    margin-left:15px;
    font-size: 30px;
}

.EventTicketCard_artistName__vopcN{
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 15px;
}

.EventTicketCard_eventDescription__9tL1S{
    margin-top: 25px;
    font-size: 16px;
    padding-right: 3%;
    padding-left: 3%;    
    text-align: left;
}

.EventTicketCard_ticketsSoldOut__17FR1{
    height: 43px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    font-weight: 600;
    font-size: 14.5px;
    background-color: red;
    height: 43px;
    color: white;
    word-break: break-word;
}

.EventTicketCard_pdfButton__1ym3n{
    height: 43px;
    width: 100%;
    background-color: #cd4335;
    color: #ffffff;
    word-break: break-word;
}

.EventTicketCard_pdfButton__1ym3n:hover {
    opacity: 0.8;
    background-color: #cd4335;
}


@media(max-width:990px){

    .EventTicketCard_infoContainer__tk0Uw{
        display: block;
        text-align: center;
        padding: 0;
    }

    .EventTicketCard_eventDescription__9tL1S{
        padding-right:25px;
        padding-left:25px;
        text-align: left;
    }

    .EventTicketCard_eventName__1pDWW{
        display: block;
    }

    .EventTicketCard_artistName__vopcN{
        display: block;
        margin-top: 20px;
    }

    .EventTicketCard_separator__8c-jL{
        display: none;
    }
}
